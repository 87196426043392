<template>
  <div>
    <v-row>
      <v-col>
        <v-toolbar flat color="transparent">
          <v-toolbar-title>
            Rejseudgifter
          </v-toolbar-title>
        </v-toolbar>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-card>
          <v-card-title>
            {{ country.name }}
          </v-card-title>

          <v-card-text>
            <v-simple-table>
              <template #default>
                <tbody>
                  <tr>
                    <td> Diæter (pr. dag) </td>
                    <td> DKK {{ country.dailyAllowance | formatNumberFixed2 }} </td>
                  </tr>
                  <tr>
                    <td> Landekode </td>
                    <td> {{ country.countryCode }} </td>
                  </tr>
                  <tr>
                    <td> Valuta </td>
                    <td> {{ country.currency }} </td>
                  </tr>
                  <tr>
                    <td> Påkrævede vacciner </td>
                    <td> {{ country.mandatoryVaccinations }} </td>
                  </tr>
                  <tr>
                    <td> Brug af pas </td>
                    <td> {{ country.passportUsage }} </td>
                  </tr>
                  <tr>
                    <td> Noter </td>
                    <td> {{ country.notes }} </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: 'SpecificationTravel',
  data() {
    return {
      loading: false,
      country: {}
    }
  },
  props: ['spec'],
  methods: {
    async getCountry(id) {
      try {
        const response = await this.$AuthService.api.get(`countries/${id}`);

        this.country = response.data;
      } catch (error) {
        console.error(error);
        this.$notifier.showError({});
      } finally {
        this.loading = false;
      }
    }
  },
  watch: {
    spec(value) {
      if (value.relationCountryId) {
        this.getCountry(value.relationCountryId);
      }
    }
  }
}
</script>
